<template>
  <transition name="slide">
    <CCard>
      <CCardBody>
        <CButton color="primary" @click="create()"
          ><CIcon name="cil-plus" /> Create New Tax Class</CButton
        >
        <br />
        <br />
        <br />
        <CAlert :color="alertType" v-if="message"> {{ message }}</CAlert>
        <CDataTable
          :items="items"
          :fields="fields"
          :items-per-page="50"
          hover
          pagination
        >
          <template #status="{ item }">
            <td>
              <CBadge :color="getBadge(item.active)">
                {{ item.active == true ? "Active" : "Inactive" }}
              </CBadge>
            </td>
          </template>
          <template #name="{ item }">
            <td>
              <strong>{{ item.name }}</strong>
            </td>
          </template>
          <template #amount="{ item }">
            <td>
              <strong>{{ item.amount }}</strong>
            </td>
          </template>
          <template #EDIT="{ item }">
            <td style="width: 15%">
              <CButton color="primary" @click="edit(item.id)"
                ><CIcon name="cil-pencil" /> Edit</CButton
              >
            </td>
          </template>
          <template #DELETE="{ item }">
            <td style="width: 15%">
              <CButton
                color="danger"
                @click="
                  warningModal = true;
                  taxClass = item;
                "
                ><CIcon name="cil-trash" /> Delete</CButton
              >
            </td>
          </template>
        </CDataTable>

        <CModal
          v-model="taxClass.id"
          title="Are you sure to delete tax class?"
          color="danger"
          :show.sync="warningModal"
          @update:show="closeModal"
        >
          <CRow>
            <CCol col="6">
              <CInput label="Name" v-model="taxClass.name" disabled />
            </CCol>
            <CCol col="6">
              <CInput label="Amount" v-model="taxClass.amount" disabled />
            </CCol>
          </CRow>
        </CModal>
      </CCardBody>
    </CCard>
  </transition>
</template>

<script>
import axios from "axios";

export default {
  name: "TaxClasses",
  data: () => {
    return {
      token: localStorage.getItem("api_token"),
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",

      //Delete
      taxClass: [],
      warningModal: false,
      items: [],
      fields: ["status", "name", "amount", "EDIT", "DELETE"],
      //Alert
      alertType: "danger",
      message: null,
    };
  },
  computed: {},
  methods: {
    getBadge(status) {
      switch (status) {
        case true:
          return "success";
        case false:
          return "secondary";
        default:
          return "primary";
      }
    },
    closeModal(status, evt, accept) {
      if (accept) {
        this.delete(this.taxClass.id);
      }
    },

    edit(id) {
      const editLink = `taxclasses/create/${id.toString()}`;
      this.$router.push({ path: editLink });
    },
    delete(id) {
      let self = this;
      axios
        .delete(`${this.$apiAdress}/v1/TaxClass/${id}`)

        .then(function () {
                    self.alertType = "success";
          setTimeout(function () {
              self.message = null;
          }, 5000);
          self.message = "Successfully deleted tax class.";
          self.getList();
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    create() {
      this.$router.push({ path: `taxclasses/create/${this.emptyGuid}` });
    },

    getList() {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/TaxClass`)
        .then(function (response) {
          self.items = response.data;
        })
        .catch(function (error) {
                    self.alertType = "danger";
          setTimeout(function () {
              self.message = null;
          }, 10000);
          self.message = error;
        });
    },
  },
  mounted: function () {
    this.getList();
  },
};
</script>

<style scoped>
.card-body >>> table > tbody > tr > td {
  cursor: pointer;
}
</style>
